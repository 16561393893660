import { Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { CompetitionSelector } from './CompetitionSelector';
import { DateRangeSelector } from './DateRangeSelector';
import { PlayerSelector } from './PlayerSelector';
import { SeasonSelector } from './SeasonSelector';
import { TeamSelector } from './TeamSelector';

export const PlayerDataLockerGeneralFilters = () => {
  const { t } = useTranslation('filters');
  return (
    <>
      <CompetitionSelector isMulti />
      <SeasonSelector />
      <TeamSelector />
      <PlayerSelector />
      <Typography variant="headingRegular">{t('dateRange')}</Typography>
      <DateRangeSelector />
    </>
  );
};
