import {
  playerAggsBaseFiltersAtom,
  playerAggsFilterParamsAtom,
  playerAggsShowApplyButtonAtom,
} from '@/atoms/queries/player/playerAggData';
import { useApplyFiltersButton } from '@/hooks/useApplyFiltersButton';
import { Button } from '@statsbomb/kitbag-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const PlayerAggsApplyFiltersButton = () => {
  const { t } = useTranslation('general');
  const { onCancel, onApply } = useApplyFiltersButton();
  const showApplyFiltersButton = useAtomValue(playerAggsShowApplyButtonAtom);
  const playerAggsFilterParams = useAtomValue(playerAggsFilterParamsAtom);
  const setPlayerAggsFilters = useSetAtom(playerAggsBaseFiltersAtom);

  return (
    showApplyFiltersButton && (
      <>
        <hr className="border-canvas-primary-main" />
        <div className="flex p-4 justify-end gap-x-2">
          <Button onClick={onCancel} variant="ghost">
            {t('cancel')}
          </Button>
          <Button
            onClick={() => {
              onApply();
              setPlayerAggsFilters(playerAggsFilterParams);
            }}
          >
            {t('apply')}
          </Button>
        </div>
      </>
    )
  );
};
