import { RefObject } from 'react';
import { snakeCase } from 'lodash/fp';
import { escapeDownloadString } from '@/utils/string';
import { Options } from 'html-to-image/lib/types';
import { getValidColumnKey } from '@/utils/table';
import { exportFontEmbeddedCSS } from './exportFontEmbeddedCSS';

export const createLinkAndDownload = (dataUrl: string, fileName: string) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = dataUrl;
  link.click();
  return link;
};

export const exportVisual = async (
  exportRef: RefObject<HTMLDivElement>,
  exportFn: <T extends HTMLElement>(node: T, options?: Options) => Promise<string>,
  fileName: string,
) => {
  if (!exportRef.current) {
    return Promise.resolve();
  }

  return exportFn(exportRef.current, {
    cacheBust: true,
    fontEmbedCSS: exportFontEmbeddedCSS,
  })
    .then(dataUrl => createLinkAndDownload(dataUrl, escapeDownloadString(fileName)))
    .catch(error => {
      throw new Error(error);
    });
};

export const toCsvFilename = (filenameWithSpaces: string) => {
  if (filenameWithSpaces.trim().length === 0) {
    return 'blank.csv';
  }

  return `${filenameWithSpaces
    .split(/(\s+)/)
    .filter(s => s.trim().length)
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join('')}.csv`;
};

export const tableColumnsToUrlParam = (columns: string[]) => {
  // if there are no columns, no need for a param
  if (!columns.length) return '';

  // convert all columns to snake case
  const snakeCaseColumns = columns.map(column => {
    // convert any "link" columns back to their original column name
    const validColumn = getValidColumnKey(column);
    const columnParts = validColumn.split('.');
    const snakeColumnName = columnParts.map(snakeCase).join('.');
    // special case for final third metrics etc
    const formattedColumnName = snakeColumnName.replace(/_3/, '3');
    return formattedColumnName;
  });

  return `&columns=${snakeCaseColumns.join(',')}`;
};
