import { PLAYER_AGGS_FILTER_ATOMS } from '@/consts/dataLocker/player';
import { useSetDataLockerDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetDataLockerDebouncedFilterParams';
import { ButtonIcon, Tabs } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { FILTERS_TAB } from '@/consts/searchKeys';
import { useShouldShowHiddenFeature } from '@/hooks/useShouldShowHiddenFeature';
import { useSetAtom } from 'jotai';
import { clearFiltersAtom } from '@/atoms/filters/resetFilters';
import { PlayerDataLockerTabValue, Tab } from '@/types/tabs';
import { playerDataLockerTabFiltersToClear } from '@/consts/filters/playerDataLocker';
import { useInitialisePlayerAggsFilters } from '@/hooks/useInitialisePlayerAggsFilters';
import { UserFilters } from '../filters/UserFilters/UserFilters';
import { PlayerDataLockerGeneralFilters } from '../filters/PlayerDataLockerGeneralFilters';
import { PlayerDataLockerPlayerFilters } from '../filters/PlayerDataLockerPlayerFilters';
import { PlayerDataLockerMetricFilters } from '../filters/PlayerDataLockerMetricFilters';
import { PlayerAggsApplyFiltersButton } from './PlayerAggsApplyFiltersButton';

const tabComponents: Record<PlayerDataLockerTabValue, () => JSX.Element> = {
  general: PlayerDataLockerGeneralFilters,
  player: PlayerDataLockerPlayerFilters,
  metrics: PlayerDataLockerMetricFilters,
};

const DebouncedFilterParams = () => {
  useSetDataLockerDebouncedFilterParams(PLAYER_AGGS_FILTER_ATOMS);
  return null;
};

export const PlayerAggsFilters = () => {
  const { t } = useTranslation(['filters', 'entity', 'general']);
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = (searchParams.get(FILTERS_TAB) || PlayerDataLockerTabValue.GENERAL) as PlayerDataLockerTabValue;

  const SelectedTabFilters = tabComponents[activeTab];
  const showHiddenFeature = useShouldShowHiddenFeature();
  useInitialisePlayerAggsFilters();

  const clearFilters = useSetAtom(clearFiltersAtom);

  return (
    <div className="h-full flex flex-col">
      <DebouncedFilterParams />
      <UserFilters />
      <hr className="border-canvas-primary-main" />
      <Tabs
        activeTab={activeTab}
        onTabChange={(_: MouseEvent, { value }: Tab<PlayerDataLockerTabValue>) => {
          const newSearch = new URLSearchParams(searchParams);
          newSearch.set(FILTERS_TAB, value);
          setSearchParams(newSearch);
        }}
        tabs={[
          {
            label: t('general'),
            value: 'general',
          },
          {
            label: t('player.one', { ns: 'entity' }),
            value: 'player',
          },
          showHiddenFeature && {
            label: t('metric.other', { ns: 'general' }),
            value: 'metrics',
          },
        ]}
      />
      <div className="flex flex-col gap-4 p-4 h-full">
        {activeTab !== 'metrics' && (
          <div>
            <ButtonIcon
              displayText="right"
              variant="secondary"
              size="small"
              icon="Erase"
              onClick={() => clearFilters(playerDataLockerTabFiltersToClear[activeTab])}
            >
              {t('clear', { ns: 'general' })}
            </ButtonIcon>
          </div>
        )}
        <SelectedTabFilters />
      </div>
      <PlayerAggsApplyFiltersButton />
    </div>
  );
};
