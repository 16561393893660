import { ReactNode } from 'react';
import { useNavigate, useOutlet } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import env from '@/env';
import { GRID_ITEM_STYLES, SCROLLABLE_GRID_ITEM_STYLES, SCROLLABLE_GRID_PARENT_STYLES } from '@/consts/grids';
import { isMobileFiltersPanelOpenAtom, isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { useAtom } from 'jotai';
import { twJoin } from 'tailwind-merge';
import { GeneralErrorBoundaryFallback } from '../errorBoundary/ErrorBoundaryFallback';
import { DebugPanel } from '../debug/DebugPanel';
import { MobileFilterButton } from '../button/MobileFilterButton';
import { MobileModalCloseButton } from '../button/MobileModalCloseButton';
import { SettingsPanel } from '../settings/SettingsPanel';

export const LayoutWithFilters = ({
  filters,
  children,
  onClickMobileClose,
}: {
  filters: ReactNode;
  children: ReactNode;
  onClickMobileClose?: () => void;
}) => {
  const outlet = useOutlet();
  const [isMobileFiltersPanelOpen, setIsMobileFiltersPanelOpen] = useAtom(isMobileFiltersPanelOpenAtom);
  const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useAtom(isSettingsPanelOpenAtom);
  const navigate = useNavigate();

  const handleCloseMobileModal = () => {
    if (onClickMobileClose) {
      onClickMobileClose();
    }

    if (outlet) {
      navigate(-1);
    } else {
      setIsSettingsPanelOpen(false);
      setIsMobileFiltersPanelOpen(false);
    }
  };

  return (
    <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
      <div className="grid-with-filters">
        <div
          className={twJoin(
            SCROLLABLE_GRID_PARENT_STYLES,
            GRID_ITEM_STYLES,
            'mobile-modal',
            !isMobileFiltersPanelOpen && !isSettingsPanelOpen && 'hidden',
          )}
        >
          {isSettingsPanelOpen && <SettingsPanel />}
          <div className={twJoin(SCROLLABLE_GRID_ITEM_STYLES, 'md:left-0', isSettingsPanelOpen && 'hidden')}>
            <MobileModalCloseButton onClick={onClickMobileClose} />
            {filters}
          </div>
        </div>
        <div className="grid-wrapper">
          <div className="h-full lg:!h-auto min-h-full flex flex-col lg:!flex-row pb-2">
            <div className={twJoin('w-full grow md:p-2', outlet && 'lg:!w-2/3')}>
              <MobileFilterButton />
              <div className="w-full h-full isolate py-2 md:p-0">{children}</div>
            </div>
            {outlet && (
              <div className="w-full lg:!w-1/3 px-2 pb-2 lg:p-0">
                <div className={twJoin(GRID_ITEM_STYLES, 'mobile-modal')}>{outlet}</div>
              </div>
            )}
          </div>
        </div>
        {(outlet || isMobileFiltersPanelOpen || isSettingsPanelOpen) && (
          <div
            data-testid="mobile-modal-bg"
            className="bg-canvas-primary-ink fixed top-0 left-0 w-full h-full opacity-sb-disabled z-10 md:hidden"
            onClick={handleCloseMobileModal}
            role="presentation"
          />
        )}
      </div>
      {/* @istanbul-ignore */}
      {env.VITE_NODE_ENV === 'development' && <DebugPanel />}
    </ErrorBoundary>
  );
};
